@import url('https://rsms.me/inter/inter.css');

:root {
  --ui-blue-000: rgba(239, 249, 250);
  --ui-blue-009: rgba(239, 249, 250, 0.9);
  --ui-blue-100: #e5fcff;
  --ui-blue-200: rgb(206, 240, 242);
  --ui-gray-200: #bababa;
  --ui-blue-300: rgb(125, 181, 237);
  --ui-blue-400: rgb(37, 99, 162);
  --ui-blue-800: #052e57;
  --ui-gray-shadow: rgba(0, 0, 0, 0.2);
  --ui-blue-shadow: rgba(5, 46, 87, 0.5);
  --ui-orange: lightcoral;
  --menu-width: 360px;
  --menu-padding: 25px;
}

html,
select,
input {
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html,
  select,
  input {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  margin: 0;
  color: var(--ui-blue-800, initial);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  background: var(--ui-blue-200);
  color: var(--ui-blue-400);
  border: 0;
  border-radius: 1.2em;
  cursor: pointer;
  height: 2.5em;
  font-size: 0.85em;
  font-weight: 500;
  padding: 0 2.5em 0 1em;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  z-index: 1;
}

.margin-top {
  margin-top: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-centre {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-justified {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rotate--90 {
  transform: rotate(-90deg);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.block {
  display: block;
}

.line-height-150 {
  line-height: 1.5;
}