.textbutton {
  position: fixed;
  z-index: 5000;
  top: 50;
  left: 50;
  margin-bottom: 50px;
}

.city_selector {
  border: 1px solid black;
  background-color: lightgrey;
  height: 40px;
}

#map {
  width: 100%;
  height: 100%;
}

.mapContainer {
  position: fixed;
  right: 0;
  width: calc(100vw - var(--menu-width));
  height: 100vh;
}

.map-control {
  display: none;
  position: fixed;
  top: 8px;
  right: 50px;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

@media only screen and (min-width: 720px) {
  .map-control {
    display: block;
  }
}
.map-control p {
  text-align: center;
  margin: 0 0 0.4rem 0;
  font-weight: 600;
  font-size: 1rem;
}

.map-control-title {
  margin: 0.2rem auto;
  font-size: 1rem;
  text-align: center;
}

.layerrow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.arrow {
  display: inline-block;
  line-height: 1.5em;
  text-align: center;
  background: inherit;
  color: black;
  font-size: 1em;
  width: 1.5em;
  transition: margin 200ms;
  right: 0;
}

.btn {
  display: inline-block;
  border: 0;
  line-height: 1.5em;
  background-color: inherit;
  outline: none;
  text-decoration: none;
}

.popup {
  font-family: 'Inter', sans-serif;
  background-color: var(--ui-blue-100);
  color: var(--ui-blue-800);
  width: 250px;
  position: relative;
  max-width: 250px;
  opacity: 0.9;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 1px var(--ui-blue-shadow);
}

.popup-heading {
  color: var(--ui-blue-800);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.2rem 0;
}

.popup-heading .popup-areaid {
  line-height: 1.3rem;
  font-size: 1.2rem;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-heading .popup-uid {
  line-height: 0.8rem;
  font-size: 0.8rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-features {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-features span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup p {
  margin: 0;
  color: var(--ui-blue-800);
  opacity: 1;
}

.popup-actions {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.popup-see-details {
  font-family: 'Inter', sans-serif;
  color: var(--ui-blue-800);
  border: 0;
  background: var(--ui-blue-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1em;
  color: var(--ui-blue-800);
  font-weight: bold;
  cursor: pointer;
}

.popup-close {
  font-family: 'Inter', sans-serif;
  color: var(--ui-blue-800);
  border: 0;
  background: 0;
  font-size: 1em;
  color: #2563a2;
  cursor: pointer;
}

.clicked-marker {
  width: 16px;
  height: 16px;
  background: var(--ui-blue-400);
  border: 3px solid var(--ui-blue-200);
  box-shadow: 0 2px 0 0 var(--ui-blue-shadow);
  border-radius: 100%;
  position: relative;
}

.clicked-marker::before {
  content: '';
  display: block;
  width: 20px;
  height: 1.5px;
  background: var(--ui-blue-200);
  box-shadow: 0 1px 0 0 var(--ui-blue-shadow);
  position: relative;
  right: -15px;
  top: calc(50% - 0.5px);
  z-index: -1;
}

.histogramParent {
  position: absolute;
  z-index: 100;
  top: 0;
  width: calc(100% - 655px);
  margin-left: var(--menu-width);
  height: 250;
  color: black;
  opacity: 0.8;
  background-color: white;
}

.histControlButton,
.HelpButton,
#styleMenu {
  background: #242a3a;
  color: white;
  width: 200px;
  height: 45px;
  margin-top: 3px;
  margin-left: 3px;
}

.HelpButton {
  position: absolute;
  z-index: 2000;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.HelpButton > a:link,
.HelpButton > a:visited,
.HelpButton > a:hover,
.HelpButton > a:active {
  color: white;
  text-decoration: none;
}
.inFront {
  position: absolute;
  z-index: 2000;
  top: 0;
}

.currentValueBar {
  width: 2px;
  height: 20px;
  background-color: red;
  position: absolute;
  z-index: 2000;
  top: 80px;
}

.vx-axis-label {
  text-anchor: middle;
  height: 20px;
}

.loadingPanel {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 20;
  top: 40%;
  left: 50%;
}

.loadingPanel > img {
  width: 150px;
  height: 150px;
}

.onboarding-backdrop {
  display: grid;
  justify-items: center;
  align-items: center;
}

.onboarding {
  width: 550px;
  height: 470px;
  box-sizing: border-box;
  padding: 10px 25px 25px;
  background: var(--ui-blue-100);
}

#onboarding-title {
  font-size: 1.2rem;
}

.onboarding-slideshow {
  width: 500px;
  height: 300px;
  position: relative;
}

.onboarding-slideshow img {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 0; 
  left: 0;
  object-fit: cover;
}

.onboarding-progressbar {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
  height: 36px;
}

.onboarding-progress {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--ui-blue-200);
}

.onboarding-progress.active {
  background-color: var(--ui-blue-400);
}

.onboarding-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.onboarding-buttons > :first-child {
  margin-right: 30px;
}