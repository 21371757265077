@import url(https://rsms.me/inter/inter.css);
:root {
  --ui-blue-000: rgba(239, 249, 250);
  --ui-blue-009: rgba(239, 249, 250, 0.9);
  --ui-blue-100: #e5fcff;
  --ui-blue-200: rgb(206, 240, 242);
  --ui-gray-200: #bababa;
  --ui-blue-300: rgb(125, 181, 237);
  --ui-blue-400: rgb(37, 99, 162);
  --ui-blue-800: #052e57;
  --ui-gray-shadow: rgba(0, 0, 0, 0.2);
  --ui-blue-shadow: rgba(5, 46, 87, 0.5);
  --ui-orange: lightcoral;
  --menu-width: 360px;
  --menu-padding: 25px;
}

html,
select,
input {
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html,
  select,
  input {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  margin: 0;
  color: #052e57;
  color: var(--ui-blue-800, initial);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

select {
  background: rgb(206, 240, 242);
  background: var(--ui-blue-200);
  color: rgb(37, 99, 162);
  color: var(--ui-blue-400);
  border: 0;
  border-radius: 1.2em;
  cursor: pointer;
  height: 2.5em;
  font-size: 0.85em;
  font-weight: 500;
  padding: 0 2.5em 0 1em;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  z-index: 1;
}

.margin-top {
  margin-top: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

.flex-left {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.flex-centre {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.flex-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.flex-justified {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.rotate--90 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.block {
  display: block;
}

.line-height-150 {
  line-height: 1.5;
}
.textbutton {
  position: fixed;
  z-index: 5000;
  top: 50;
  left: 50;
  margin-bottom: 50px;
}

.city_selector {
  border: 1px solid black;
  background-color: lightgrey;
  height: 40px;
}

#map {
  width: 100%;
  height: 100%;
}

.mapContainer {
  position: fixed;
  right: 0;
  width: calc(100vw - var(--menu-width));
  height: 100vh;
}

.map-control {
  display: none;
  position: fixed;
  top: 8px;
  right: 50px;
  background: white;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 700;
  color: #333;
  text-align: center;
}

@media only screen and (min-width: 720px) {
  .map-control {
    display: block;
  }
}
.map-control p {
  text-align: center;
  margin: 0 0 0.4rem 0;
  font-weight: 600;
  font-size: 1rem;
}

.map-control-title {
  margin: 0.2rem auto;
  font-size: 1rem;
  text-align: center;
}

.layerrow {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 5px;
}

.arrow {
  display: inline-block;
  line-height: 1.5em;
  text-align: center;
  background: inherit;
  color: black;
  font-size: 1em;
  width: 1.5em;
  transition: margin 200ms;
  right: 0;
}

.btn {
  display: inline-block;
  border: 0;
  line-height: 1.5em;
  background-color: inherit;
  outline: none;
  text-decoration: none;
}

.popup {
  font-family: 'Inter', sans-serif;
  background-color: var(--ui-blue-100);
  color: var(--ui-blue-800);
  width: 250px;
  position: relative;
  max-width: 250px;
  opacity: 0.9;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 1px var(--ui-blue-shadow);
}

.popup-heading {
  color: var(--ui-blue-800);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0.2rem 0;
}

.popup-heading .popup-areaid {
  line-height: 1.3rem;
  font-size: 1.2rem;
  font-weight: 800;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-heading .popup-uid {
  line-height: 0.8rem;
  font-size: 0.8rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-features {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.popup-features span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup p {
  margin: 0;
  color: var(--ui-blue-800);
  opacity: 1;
}

.popup-actions {
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 30px;
}

.popup-see-details {
  font-family: 'Inter', sans-serif;
  color: var(--ui-blue-800);
  border: 0;
  background: var(--ui-blue-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1em;
  color: var(--ui-blue-800);
  font-weight: bold;
  cursor: pointer;
}

.popup-close {
  font-family: 'Inter', sans-serif;
  color: var(--ui-blue-800);
  border: 0;
  background: 0;
  font-size: 1em;
  color: #2563a2;
  cursor: pointer;
}

.clicked-marker {
  width: 16px;
  height: 16px;
  background: var(--ui-blue-400);
  border: 3px solid var(--ui-blue-200);
  box-shadow: 0 2px 0 0 var(--ui-blue-shadow);
  border-radius: 100%;
  position: relative;
}

.clicked-marker::before {
  content: '';
  display: block;
  width: 20px;
  height: 1.5px;
  background: var(--ui-blue-200);
  box-shadow: 0 1px 0 0 var(--ui-blue-shadow);
  position: relative;
  right: -15px;
  top: calc(50% - 0.5px);
  z-index: -1;
}

.histogramParent {
  position: absolute;
  z-index: 100;
  top: 0;
  width: calc(100% - 655px);
  margin-left: var(--menu-width);
  height: 250;
  color: black;
  opacity: 0.8;
  background-color: white;
}

.histControlButton,
.HelpButton,
#styleMenu {
  background: #242a3a;
  color: white;
  width: 200px;
  height: 45px;
  margin-top: 3px;
  margin-left: 3px;
}

.HelpButton {
  position: absolute;
  z-index: 2000;
  opacity: 0.8;
  top: 0;
  left: 0;
}
.HelpButton > a:link,
.HelpButton > a:visited,
.HelpButton > a:hover,
.HelpButton > a:active {
  color: white;
  text-decoration: none;
}
.inFront {
  position: absolute;
  z-index: 2000;
  top: 0;
}

.currentValueBar {
  width: 2px;
  height: 20px;
  background-color: red;
  position: absolute;
  z-index: 2000;
  top: 80px;
}

.vx-axis-label {
  text-anchor: middle;
  height: 20px;
}

.loadingPanel {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 20;
  top: 40%;
  left: 50%;
}

.loadingPanel > img {
  width: 150px;
  height: 150px;
}

.onboarding-backdrop {
  display: grid;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
}

.onboarding {
  width: 550px;
  height: 470px;
  box-sizing: border-box;
  padding: 10px 25px 25px;
  background: var(--ui-blue-100);
}

#onboarding-title {
  font-size: 1.2rem;
}

.onboarding-slideshow {
  width: 500px;
  height: 300px;
  position: relative;
}

.onboarding-slideshow img {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 0; 
  left: 0;
  object-fit: cover;
}

.onboarding-progressbar {
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 40px;
  height: 36px;
}

.onboarding-progress {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--ui-blue-200);
}

.onboarding-progress.active {
  background-color: var(--ui-blue-400);
}

.onboarding-actions {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 5px;
}

.onboarding-buttons > :first-child {
  margin-right: 30px;
}
#menu {
  box-sizing: border-box;
  position: fixed;
  width: var(--menu-width);
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--ui-blue-000);
  box-shadow: 3px 0px 3px var(--ui-gray-shadow);
  z-index: 10;
}

.menu-header {
  background-color: var(--ui-blue-100);
  padding: var(--menu-padding);
  padding-top: calc(1.2 * var(--menu-padding));
  border-bottom: 1px solid var(--ui-blue-200);
}

#menu h1 {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 900;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin: 0;
}

#menu h2 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0.75rem 0;
}

#menu h3 {
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 0.5rem;
}

.data-dictionary h3 {
  margin: 0.4rem 0 0.2rem 0;
}

.data-dictionary p {
  margin: 0.2rem 0 0.8rem 0;
}

.menu-header h3 {
  margin: 1rem 0 0.25rem 0;
}

.menu-tagline {
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem 0;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
  margin: 0 0.5em 0 0;
}

.dropdown-wrapper::after {
  display: block;
  line-height: 2.2em;
  content: '▾';
  position: absolute;
  right: 0;
  top: 0;
  color: var(--ui-blue-400);
  width: 2.2em;
  height: 2.2em;
  z-index: 2;
  pointer-events: none;
}

* + .dropdown-wrapper {
  margin: 0 0.5em;
}

.dropdown-small {
  max-width: 100px;
}

.dropdown-medium {
  max-width: 125px;
}

.dropdown-large {
  max-width: 200px;
}

* + .dropdown-small,
* + .dropdown-medium {
  margin-left: 5px;
}

.menu-px {
  padding-left: 2rem;
  padding-right: 2rem;
}

.menu-section:not(:last-child) {
  border-bottom: 1px solid var(--ui-blue-200);
}

.menu-section:last-child {
  padding-bottom: 2rem;
}

.menu-attribute {
  box-sizing: border-box;
  height: 1.7rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 0.5rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-size: 0.9rem;
  font-weight: 700;
}

.attribute-icon {
  width: 18px;
  height: 18px;
  position: relative;
  color: var(--ui-blue-300);
}

.attribute-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-tooltip {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  cursor: help;
  width: 18px;
  height: 18px;
  color: var(--ui-blue-300);
  background: var(--ui-blue-200);
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  font-style: italic;
}

.attribute-tooltip {
  cursor: help;
}

.attributePanel {
  background-color: white;
  color: black;
  width: 100%;
}

.disabledString {
  color: grey;
}

.menu-slider {
  width: 150px;
  margin-right: 10px;
}

.disabledSection {
  pointer-events: none;
  opacity: 0.7;
}

.menu-about p {
  font-size: 0.8rem;
}

/*******************************/
/* LINEAR COLOUR SELECTION */
.menu-color {
  display: grid;
  grid-template-rows: 40px 20px auto 40px;
  grid-template-columns: 120px 120px;
  font-size: 0.75rem;
}

.linear-histogram {
  grid-row: 1;
  grid-column: 1;
}

.linear-line {
  grid-row: 2;
  grid-column: 1;
}

.color-squares {
  grid-row: 3;
  grid-column: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 120px;
}

.color-square-number {
  grid-row: 3;
  grid-column: 2;
}

.linear-attriute-dropdown {
  grid-row: 4;
  grid-column: 1;
}

.linear-histogram-legend {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.75em;
  color: var(--ui-blue-300);
  font-style: italic;
  text-align: left;
  padding-left: 20px;
}

.col-square {
  box-sizing: border-box;
  border: 3px solid transparent;
  transition: border 0.2s ease-out;
}

.col-square:hover {
  border: 2px solid var(--ui-orange);
}

.col-square-6 {
  width: 20px;
  height: 20px;
}

.col-square-3 {
  width: 40px;
  height: 40px;
}

/*******************************/
/* BIVARIATE COLOUR SELECTION */
.menu-bivariate-colour {
  margin: 1em 0 0.5em;
}

.biv-selection {
  width: 220px;
  height: 220px;
  font-size: 0.75rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 40px 40px 20px repeat(3, 40px);
  grid-template-rows: repeat(3, 40px) 20px 40px;
}

.biv-hist-vertical {
  grid-row: 1 / span 3;
  grid-column: 2;
  -webkit-transform: rotate(-90deg) translate(-120px);
          transform: rotate(-90deg) translate(-120px);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

.biv-hist-horizontal {
  grid-row: 5;
  grid-column: 4 / span 3;
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1);
}

.biv-dropdown-horizontal {
  grid-row: 6;
  grid-column: 2 / span 5;
  -webkit-transform: translate(10px);
          transform: translate(10px);
}

.biv-dropdown-vertical {
  grid-row: 1 / span 5;
  grid-column: 1;
  -webkit-transform: rotate(90deg) translate(35px);
          transform: rotate(90deg) translate(35px);
}

.biv-square {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  transition: border 0.2s ease-out;
  z-index: 1;
}

.biv-square:hover {
  border: 2px solid var(--ui-orange);
}

.biv-square:nth-child(1) {
  grid-row: 1;
  grid-column: 4;
}
.biv-square:nth-child(2) {
  grid-row: 1;
  grid-column: 5;
}
.biv-square:nth-child(3) {
  grid-row: 1;
  grid-column: 6;
}
.biv-square:nth-child(4) {
  grid-row: 2;
  grid-column: 4;
}
.biv-square:nth-child(5) {
  grid-row: 2;
  grid-column: 5;
}
.biv-square:nth-child(6) {
  grid-row: 2;
  grid-column: 6;
}
.biv-square:nth-child(7) {
  grid-row: 3;
  grid-column: 4;
}
.biv-square:nth-child(8) {
  grid-row: 3;
  grid-column: 5;
}
.biv-square:nth-child(9) {
  grid-row: 3;
  grid-column: 6;
}

.biv-line-h {
  grid-row: 4;
  grid-column: 4 / span 3;
}
.biv-line-v {
  grid-row: 1 / span 3;
  grid-column: 3;
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
}
.long-dash {
  width: 50%;
  height: 1px;
  background-color: var(--ui-blue-300);
}
.long-dash-vertical {
  width: 1px;
  height: 50px;
  background-color: var(--ui-blue-300);
}

.biv-hist-legend {
  grid-row: 5;
  grid-column: 2;
  font-size: 0.75em;
  color: var(--ui-blue-300);
  font-style: italic;
  text-align: right;
}
/*******************************/
/* NEIGHBOURHOOD CHARACTERISTICS */

/* no easy way to style the inside of component so we do it here */
.neighbourhood .MuiPaper-root {
  width: 334px;
  padding: 0 2em;
  font-size: 0.9rem;
  background: var(--ui-blue-000);
  box-sizing: border-box;
}

.neighbourhood h4 {
  font-size: 0.9rem;
  margin: 1rem 0 0.5rem;
}

.neighbourhood-line {
  border-top: 1px solid var(--ui-blue-300);
  font-size: 0.75rem;
  width: 200px;
}

/*******************************/
/* COMMON COLOUR SELECTION STYULES */

.colourActive {
  border: 2px solid var(--ui-blue-800) !important;
}
.iconActive {
  border: 2px solid var(--ui-blue-400) !important;
}


.historgram-rect {
  transition: stroke 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

.menu-hover-icons, .menu-hover-icons-height {
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: right;
          justify-content: right;
  margin-bottom:10px;
}

.menu-hover-icons {
  margin-top: -5px;
}

.menu-radi-height{
  display: grid;
  grid-template-rows: 25px 25px;
  grid-template-columns:100%;
  font-size: 0.75rem;
  grid-gap: 5px 0;
  gap: 5px 0; 
  justify-items: end; 
  margin-top:-10px;
  margin-bottom:20px;
}

.menu-hover-icon-container-radius {
  min-width:25px;
  text-align:center;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items:center;
          align-items:center;
  border: 2px solid transparent;
}

.menu-hover-icon-container-radius:hover {
  border: 2px solid var(--ui-orange);
}
.menu-hover-icon-container-height:hover {
  border: 2px solid var(--ui-orange);
}

.menu-hover-icon-container-height {
  min-width:25px;
  -webkit-align-items:flex-end;
          align-items:flex-end;
  display:-webkit-flex;
  display:flex;
  height:100%;
  border:2px solid transparent;
}

.menu-hover-icon-container-height>.hover-icon{
  display:inline-block;
  margin: auto auto 0 auto;
}

.hover-line{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: right;
          justify-content: right;
  -webkit-align-items: center;
          align-items: center;
  width: 240px;
}

.hover-line>span{
  margin-left:3px;
}

