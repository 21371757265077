#menu {
  box-sizing: border-box;
  position: fixed;
  width: var(--menu-width);
  height: 100vh;
  overflow-x: hidden;
  background-color: var(--ui-blue-000);
  box-shadow: 3px 0px 3px var(--ui-gray-shadow);
  z-index: 10;
}

.menu-header {
  background-color: var(--ui-blue-100);
  padding: var(--menu-padding);
  padding-top: calc(1.2 * var(--menu-padding));
  border-bottom: 1px solid var(--ui-blue-200);
}

#menu h1 {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 900;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
}

#menu h2 {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
  margin: 0.75rem 0;
}

#menu h3 {
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 0.5rem;
}

.data-dictionary h3 {
  margin: 0.4rem 0 0.2rem 0;
}

.data-dictionary p {
  margin: 0.2rem 0 0.8rem 0;
}

.menu-header h3 {
  margin: 1rem 0 0.25rem 0;
}

.menu-tagline {
  font-size: 0.9rem;
  margin: 0.5rem 0 1rem 0;
}

.dropdown-wrapper {
  display: inline-block;
  position: relative;
  margin: 0 0.5em 0 0;
}

.dropdown-wrapper::after {
  display: block;
  line-height: 2.2em;
  content: '▾';
  position: absolute;
  right: 0;
  top: 0;
  color: var(--ui-blue-400);
  width: 2.2em;
  height: 2.2em;
  z-index: 2;
  pointer-events: none;
}

* + .dropdown-wrapper {
  margin: 0 0.5em;
}

.dropdown-small {
  max-width: 100px;
}

.dropdown-medium {
  max-width: 125px;
}

.dropdown-large {
  max-width: 200px;
}

* + .dropdown-small,
* + .dropdown-medium {
  margin-left: 5px;
}

.menu-px {
  padding-left: 2rem;
  padding-right: 2rem;
}

.menu-section:not(:last-child) {
  border-bottom: 1px solid var(--ui-blue-200);
}

.menu-section:last-child {
  padding-bottom: 2rem;
}

.menu-attribute {
  box-sizing: border-box;
  height: 1.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  font-size: 0.9rem;
  font-weight: 700;
}

.attribute-icon {
  width: 18px;
  height: 18px;
  position: relative;
  color: var(--ui-blue-300);
}

.attribute-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-tooltip {
  flex-shrink: 0;
  cursor: help;
  width: 18px;
  height: 18px;
  color: var(--ui-blue-300);
  background: var(--ui-blue-200);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-style: italic;
}

.attribute-tooltip {
  cursor: help;
}

.attributePanel {
  background-color: white;
  color: black;
  width: 100%;
}

.disabledString {
  color: grey;
}

.menu-slider {
  width: 150px;
  margin-right: 10px;
}

.disabledSection {
  pointer-events: none;
  opacity: 0.7;
}

.menu-about p {
  font-size: 0.8rem;
}

/*******************************/
/* LINEAR COLOUR SELECTION */
.menu-color {
  display: grid;
  grid-template-rows: 40px 20px auto 40px;
  grid-template-columns: 120px 120px;
  font-size: 0.75rem;
}

.linear-histogram {
  grid-row: 1;
  grid-column: 1;
}

.linear-line {
  grid-row: 2;
  grid-column: 1;
}

.color-squares {
  grid-row: 3;
  grid-column: 1;
  display: flex;
  justify-content: center;
  width: 120px;
}

.color-square-number {
  grid-row: 3;
  grid-column: 2;
}

.linear-attriute-dropdown {
  grid-row: 4;
  grid-column: 1;
}

.linear-histogram-legend {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.75em;
  color: var(--ui-blue-300);
  font-style: italic;
  text-align: left;
  padding-left: 20px;
}

.col-square {
  box-sizing: border-box;
  border: 3px solid transparent;
  transition: border 0.2s ease-out;
}

.col-square:hover {
  border: 2px solid var(--ui-orange);
}

.col-square-6 {
  width: 20px;
  height: 20px;
}

.col-square-3 {
  width: 40px;
  height: 40px;
}

/*******************************/
/* BIVARIATE COLOUR SELECTION */
.menu-bivariate-colour {
  margin: 1em 0 0.5em;
}

.biv-selection {
  width: 220px;
  height: 220px;
  font-size: 0.75rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 40px 40px 20px repeat(3, 40px);
  grid-template-rows: repeat(3, 40px) 20px 40px;
}

.biv-hist-vertical {
  grid-row: 1 / span 3;
  grid-column: 2;
  transform: rotate(-90deg) translate(-120px);
  transform-origin: 0% 0%;
}

.biv-hist-horizontal {
  grid-row: 5;
  grid-column: 4 / span 3;
  transform: scaleY(-1);
}

.biv-dropdown-horizontal {
  grid-row: 6;
  grid-column: 2 / span 5;
  transform: translate(10px);
}

.biv-dropdown-vertical {
  grid-row: 1 / span 5;
  grid-column: 1;
  transform: rotate(90deg) translate(35px);
}

.biv-square {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  transition: border 0.2s ease-out;
  z-index: 1;
}

.biv-square:hover {
  border: 2px solid var(--ui-orange);
}

.biv-square:nth-child(1) {
  grid-row: 1;
  grid-column: 4;
}
.biv-square:nth-child(2) {
  grid-row: 1;
  grid-column: 5;
}
.biv-square:nth-child(3) {
  grid-row: 1;
  grid-column: 6;
}
.biv-square:nth-child(4) {
  grid-row: 2;
  grid-column: 4;
}
.biv-square:nth-child(5) {
  grid-row: 2;
  grid-column: 5;
}
.biv-square:nth-child(6) {
  grid-row: 2;
  grid-column: 6;
}
.biv-square:nth-child(7) {
  grid-row: 3;
  grid-column: 4;
}
.biv-square:nth-child(8) {
  grid-row: 3;
  grid-column: 5;
}
.biv-square:nth-child(9) {
  grid-row: 3;
  grid-column: 6;
}

.biv-line-h {
  grid-row: 4;
  grid-column: 4 / span 3;
}
.biv-line-v {
  grid-row: 1 / span 3;
  grid-column: 3;
  writing-mode: vertical-rl;
}
.long-dash {
  width: 50%;
  height: 1px;
  background-color: var(--ui-blue-300);
}
.long-dash-vertical {
  width: 1px;
  height: 50px;
  background-color: var(--ui-blue-300);
}

.biv-hist-legend {
  grid-row: 5;
  grid-column: 2;
  font-size: 0.75em;
  color: var(--ui-blue-300);
  font-style: italic;
  text-align: right;
}
/*******************************/
/* NEIGHBOURHOOD CHARACTERISTICS */

/* no easy way to style the inside of component so we do it here */
.neighbourhood .MuiPaper-root {
  width: 334px;
  padding: 0 2em;
  font-size: 0.9rem;
  background: var(--ui-blue-000);
  box-sizing: border-box;
}

.neighbourhood h4 {
  font-size: 0.9rem;
  margin: 1rem 0 0.5rem;
}

.neighbourhood-line {
  border-top: 1px solid var(--ui-blue-300);
  font-size: 0.75rem;
  width: 200px;
}

/*******************************/
/* COMMON COLOUR SELECTION STYULES */

.colourActive {
  border: 2px solid var(--ui-blue-800) !important;
}
.iconActive {
  border: 2px solid var(--ui-blue-400) !important;
}


.historgram-rect {
  transition: stroke 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
}

.menu-hover-icons, .menu-hover-icons-height {
  display:flex;
  justify-content: right;
  margin-bottom:10px;
}

.menu-hover-icons {
  margin-top: -5px;
}

.menu-radi-height{
  display: grid;
  grid-template-rows: 25px 25px;
  grid-template-columns:100%;
  font-size: 0.75rem;
  gap: 5px 0; 
  justify-items: end; 
  margin-top:-10px;
  margin-bottom:20px;
}

.menu-hover-icon-container-radius {
  min-width:25px;
  text-align:center;
  display:flex;
  justify-content: center;
  align-items:center;
  border: 2px solid transparent;
}

.menu-hover-icon-container-radius:hover {
  border: 2px solid var(--ui-orange);
}
.menu-hover-icon-container-height:hover {
  border: 2px solid var(--ui-orange);
}

.menu-hover-icon-container-height {
  min-width:25px;
  align-items:flex-end;
  display:flex;
  height:100%;
  border:2px solid transparent;
}

.menu-hover-icon-container-height>.hover-icon{
  display:inline-block;
  margin: auto auto 0 auto;
}

.hover-line{
  display: flex;
  justify-content: right;
  align-items: center;
  width: 240px;
}

.hover-line>span{
  margin-left:3px;
}
